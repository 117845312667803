import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Top from '../components/PersonaMultiplePage/Top'
import Articles from '../components/PersonaMultiplePage/Articles'
import Quiz from '../components/PersonaMultiplePage/Quiz'
import Trt from '../components/PersonaMultiplePage/Trt'
import Science from '../components/PersonaMultiplePage/Science'
import Benefits from '../components/PersonaMultiplePage/Benefits'
import Price from '../components/PersonaMultiplePage/Price'
import Reviews from '../components/PersonaMultiplePage/Reviews'
import Info from '../components/PersonaMultiplePage/Info'

export default ({ pageContext: { data } }) => {
  return (
    <Layout
      data={data}
      signUpUrl="https://app.maximustribe.com/registration"
      signUpText="Start online visit"
      signInHide={true}
    >
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      <Top top={data.top} />
      <Articles articles={data.articles} />
      <Quiz quiz={data.quiz} />
      <Trt trt={data.trt} />
      <Science science={data.science} table={data.table} />
      <Benefits benefits={data.benefits} />
      <Price price={data.price} />
      <Reviews reviews={data.reviews} />
      <Info info={data.info} />
    </Layout>
  )
}

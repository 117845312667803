import React from 'react'
import styles from './style.module.sass'

export default ({ trt }) => {
  return (
    <div className={styles.trt}>
      <div className={styles.wrap}>
        <p className={styles.top}>{trt.titleTop}</p>
        <h2 className={styles.title}>{trt.title}</h2>
        <div className={styles.border} />
        <p className={styles.bottom}>{trt.titleBottom}</p>
      </div>
    </div>
  )
}
